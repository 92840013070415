define("eranovic-de/templates/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hVlbywVm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container text-center\"],[9],[0,\"\\n    \"],[7,\"form\"],[11,\"class\",\"form-signin\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"class\",\"mb-4\"],[11,\"src\",\"/assets/images/lock.png\"],[11,\"alt\",\"\"],[11,\"width\",\"72\"],[11,\"height\",\"72\"],[9],[10],[0,\"\\n        \"],[7,\"h1\"],[11,\"class\",\"h3 mb-3 font-weight-normal\"],[9],[0,\"Please sign in\"],[10],[0,\"\\n        \"],[7,\"label\"],[11,\"for\",\"inputEmail\"],[11,\"class\",\"sr-only\"],[9],[0,\"Email address\"],[10],[0,\"\\n        \"],[7,\"input\"],[11,\"id\",\"inputEmail\"],[11,\"class\",\"form-control\"],[11,\"placeholder\",\"Email address\"],[11,\"required\",\"\"],[11,\"autofocus\",\"\"],[11,\"type\",\"email\"],[9],[10],[0,\"\\n        \"],[7,\"label\"],[11,\"for\",\"inputPassword\"],[11,\"class\",\"sr-only\"],[9],[0,\"Password\"],[10],[0,\"\\n        \"],[7,\"input\"],[11,\"id\",\"inputPassword\"],[11,\"class\",\"form-control\"],[11,\"placeholder\",\"Password\"],[11,\"required\",\"\"],[11,\"type\",\"password\"],[9],[10],[0,\"\\n        \\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-lg btn-primary btn-block\"],[11,\"type\",\"submit\"],[9],[0,\"Sign in\"],[10],[0,\"\\n        \\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eranovic-de/templates/home.hbs"
    }
  });

  _exports.default = _default;
});